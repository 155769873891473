import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap, { Power2 } from 'gsap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './blog.css';
import dots from '../../assets/images/dots.png';

const BlogContent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let root = useRef(null);

  useLayoutEffect(() => {
    const tl = gsap.timeline();
    let ctx = gsap.context(() => {
      tl.from('.img-fluid', { opacity: 0, ease: Power2.easeIn }, '0.1')
        .to('.gap-bottom', { opacity: 1 }, 0.1)
        .to('header', { opacity: 1, ease: Power2.easeIn }, 0.1)
        .to('.dots-img', { opacity: 1, ease: Power2.easeIn }, 0.3)
        .to('.p_content', { opacity: 1, ease: Power2.easeIn }, 0.3);
    }, root);

    return () => ctx.revert();
  }, []);

  const navigate = useNavigate();

  const handleDetails = (id) => {
    navigate(`/blogs/${id}`);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://gbs-website.work.gd/api/blogs?populate=*`
        );
        setData(response?.data?.data || []);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, []);

  return (
    <div className='platforms-content-wrapper' ref={root}>
      <div className='container'>
        {loading
          ? Array(3)
              .fill(0)
              .map((_, index) => (
                <div key={index} className='row gap gap-bottom'>
                  <div className='col-lg-5'>
                    <Skeleton height={250} className='skeleton-img' />
                  </div>
                  <div className='col-lg-6'>
                    <Skeleton height={30} width={200} className='mb-3' />
                    <Skeleton count={3} className='mb-2' />
                    <Skeleton width={100} height={40} className='mt-3' />
                  </div>
                </div>
              ))
          : data.map((list, index) => {
              const { id, Title, content, Media } = list;
              const imageUrl = Media?.url;
              return (
                <div key={id}>
                  <div
                    id={id}
                    className={
                      index % 2 === 0
                        ? 'row gap gap-bottom'
                        : 'row gap gap-bottom row-reverse'
                    }
                  >
                    <div
                      className={
                        index % 2 === 0
                          ? 'col-lg-5 mr-auto'
                          : 'col-lg-5 ml-auto'
                      }
                    >
                      <img
                        className='img-fluid blog-img mb-4'
                        src={`https://gbs-website.work.gd${imageUrl}`}
                        alt={id}
                        loading='lazy'
                      />
                    </div>
                    <div className='col-lg-6'>
                      <div className='blog-section'>
                        <img className='dots-img' src={dots} alt='' />
                        <header className='mb-5 text-capitalize'>
                          {Title}
                        </header>
                      </div>
                      <p className='date-section mt-5 text-muted'>
                        <i className='pi pi-calendar'></i>{' '}
                        {new Date(list?.createdAt).toLocaleDateString('en-US')}
                      </p>
                      <div className='p_content blog_list mt-3'>
                        {content?.map((infoItem, idx) => {
                          if (infoItem.type === 'paragraph') {
                            return (
                              <p key={infoItem.id || idx} className='mb-3'>
                                {infoItem?.children?.map(
                                  (child) =>
                                    !child.code && (
                                      <span key={child.id || child.text}>
                                        {child?.text || ''}
                                      </span>
                                    )
                                )}
                              </p>
                            );
                          }
                          return null;
                        })}
                      </div>

                      <button
                        onClick={() => handleDetails(list.id)}
                        className='btn button-primary-blog mt-3 mb-4'
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default BlogContent;
