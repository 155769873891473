import React, { Fragment } from 'react';
import Navbar from '../compontents/Navbar';
import { BannerSmall, BlogContent, FooterCommon } from '../compontents';
import BlogImage from '../assets/images/blog.png';

const Blogs = () => {
  return (
    <Fragment>
      <Navbar />
      <BannerSmall image={BlogImage} styleClass={'blog'} headding={'Blog'} />
      <BlogContent />
      <FooterCommon />
    </Fragment>
  )
};

export default Blogs;
