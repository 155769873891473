import { Fragment } from 'react';
import { MemoryRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import {
  BlogDetailedView,
  CaseStudySingle,
  OfferingsSinglePage,
} from './compontents';
import {
  Home,
  About,
  Offerings,
  Platforms,
  Clients,
  Casestudies,
  ContactUs,
  Blogs,
} from './pages';

function App() {
  return (
    <Fragment>
      <ScrollToTop smooth />
      <MemoryRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='offerings' element={<Offerings />} />
          <Route
            path='offerings/:offeringsId'
            element={<OfferingsSinglePage />}
          />
          <Route path='platforms' element={<Platforms />} />
          <Route path='clients' element={<Clients />} />
          <Route path='case-studies' element={<Casestudies />} />
          <Route
            path='case-studies/:caseStudiesId'
            element={<CaseStudySingle />}
          />
          <Route path='blogs' element={<Blogs />} />
          <Route path='blogs/:id' element={<BlogDetailedView />} />
          <Route path='contact-us' element={<ContactUs />} />
        </Routes>
      </MemoryRouter>
    </Fragment>
  );
}

export default App;
